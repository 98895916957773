import axios from "axios";
import { HmacSHA256, enc } from "crypto-js";

// Constants
const clientId = import.meta.env.VITE_MEMEPOP_CLIENT_ID;
const clientSecret = import.meta.env.VITE_MEMEPOP_CLIENT_SECRET;
const gameId = import.meta.env.VITE_MEMEPOP_GAME_ID;

// Define the request function
export async function sendMemepopCustomEvent(user: any, triggeredType: string) {
  // Message body

  let body;

  if (triggeredType === "event") {
    body = {
      gameId: gameId,
      events: [{ userId: `${user?.id}`, event: "START_GAME", time: new Date().toISOString() }],
    };
  } else {
    const username = user?.username?.split("__")[0];

    body = {
      gameId: gameId,
      identifiers: [{ userId: `${user?.id}`, telegramId: `${username}` }],
    };
  }

  // Generate timestamp
  const timestamp = Date.now().toString();

  // Generate signature
  const message = `${clientId}${timestamp}${JSON.stringify(body)}`;
  const signature = HmacSHA256(message, clientSecret).toString(enc.Hex);

  // Define headers
  const headers = {
    "x-client-id": clientId,
    "x-timestamp": timestamp,
    "x-signature": signature,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post("https://events.earnalliance.com/v2/custom-events", body, {
      headers: headers,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error response:", error.response?.data);
    } else {
      console.error("Error:", error);
    }
  }
}
