import React, { useContext } from "react";
import { css } from "@emotion/react";

import cn from "classnames";
import Modal from "react-bootstrap/Modal";
import { FaTimes } from "react-icons/fa";

import { WindowSizeContext } from "providers/WindowSizeProvider";
import analytics from "utils/analytics";
import { decodeSearchDataFromURL } from "utils";
import { useAppDispatch } from "redux/Store";
import { showLeaderboardPopup } from "redux/reducers/layoutReducer";

const campaignMessageModalCss = (theme: ITheme) => css`
  .modal-content {
    /*width: 90%; */
    width: unset;
    /* background-color: ${theme.background.primary}; */

    /* @media (min-width: 1023px) and (max-width: 1900px) {
      width: 60%;
    } */
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    color: ${theme.text.primary};
  }

  .modal_title,
  .message {
    color: ${theme.text.primary};
  }

  .internal-title {
    font-weight: 600;
    color: ${theme.text.primary};
    font-size: 1.1rem;
  }

  .image-style {
    max-width: 100%;
  }

  .modal_title {
    font-size: 1.5rem;
    font-weight: 600;
    font-size: 1.2rem;
    /* @media (min-width: 300px) and (max-width: 420px) {
      font-size: 22px;
    } */
  }

  .message {
    font-weight: 600;
    font-size: 0.9rem;
  }

  .trophy {
    width: 200px;
  }

  .scrollable-class {
    max-height: 550px;
    overflow-y: scroll;
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

const button = (theme: ITheme) => css`
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 50px;
  padding-left: 50px;
  box-shadow: 0px 4px 7px 1px ${theme.button.primaryShadow};
  font-weight: 500;
  border: none;
  font-size: 20px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  @media (min-width: 300px) and (max-width: 359px) {
    font-size: 16px;
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: 360px) and (max-width: 420px) {
    font-size: 18px;
    padding-right: 50px;
    padding-left: 50px;
  }
`;

const deskCss = (theme: ITheme) => css`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &.desktop-modal {
    position: fixed;
    left: 50%;
    transform: translateY(-50%);
    margin-right: -134px;
    /* width: 305px; */
    background-color: ${theme.background.primary};
    top: 50%;
    border-radius: none !important;
  }

  &.desktop-modal::-webkit-scrollbar {
    display: none;
  }

  .modal-body {
    /* background-color: #ffffff !important; */
    /* width: 165%; */
    padding: 0;
  }

  .scrollable-class {
    max-height: 750px;
    overflow-y: scroll;
  }

  .image-style {
    max-width: 100%;
    max-height: 430px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    color: ${theme.text.primary};
    cursor: pointer;
  }

  .modal-dialog {
    display: flex;
    /* max-width: 800px; */
  }

  .modal-dialog::-webkit-scrollbar {
    display: none;
  }

  .modal-content {
    /* width: 165%; */
    align-items: center;
    position: absolute;
    /* right: 50%;
    margin-right: -132px;
    margin-top: -269px;
    top: 50%; */
    border: none;
    border-radius: 0 !important;
    width: unset;
    /* border-radius: 1.3rem; */
  }

  .modal_title {
    font-weight: 600;
    color: ${theme.text.primary};
    font-size: 2rem;
  }

  .internal-title {
    font-weight: 600;
    color: ${theme.text.primary};
  }

  .message {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    color: ${theme.text.primary};
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;

export const CampaignMessageModal = ({ show, onHide, message_template }: any) => {
  const dispatch = useAppDispatch();
  const closeModal = () => {
    onHide();
  };

  const breakpoint = 1022;
  const { width } = useContext(WindowSizeContext);

  const handleClick = () => {
    // onHide();
    try {
      // const url = new URL(message_template?.action_url);
      // const path = url.pathname.split("/")[1];
      // console.log({ path });
      if (message_template?.action_url) {
        window.location.assign(message_template?.action_url);
      }
      // console.log(`${url.pathname}${url?.search || ""}`);
      const { tournament = null, open_prize = null } = decodeSearchDataFromURL();
      if (tournament) {
        dispatch(
          showLeaderboardPopup({
            tournament: +tournament,
            openPrize: open_prize,
          }),
        );
      }
      analytics.clickedWelcomeBanner(message_template?.action_url);
    } catch (e) {
      // console.log({ e });
    }
    // const params = new URLSearchParams(url.search);
    // console.log(url.search);
    // const host = window.location.host;
    // const path = message_template?.action_url.split(host)[1];
    // console.log(message_template?.action_url, host, path);
    // history.push(path);
    onHide();
  };

  return (
    <Modal
      show={show}
      centered
      onHide={closeModal}
      contentClassName="campaign-message-modal"
      css={breakpoint < width ? deskCss : campaignMessageModalCss}
    >
      <Modal.Body
        className={cn({
          "position-relative": message_template?.message !== "",
          "p-0": message_template?.message === "",
        })}
        // style={{
        //   backgroundColor: message_template?.background_color,
        // }}
      >
        <div className="border-2 bg-black h-5 w-5 text-end p-2">
          <FaTimes className="cursor-pointer" onClick={onHide} />
        </div>
        <div
          style={{
            color: message_template?.message_text_color,
          }}
        >
          {message_template?.title !== null && message_template?.title !== "" && (
            <h1 className="modal_title text-center pt-3">{message_template?.title}</h1>
          )}

          {/* {message_template?.internal_title !== null && message_template?.internal_title !== "" && (
            <h4 className="internal-title text-center my-1">{message_template?.internal_title}</h4>
          )} */}

          {message_template?.message !== null && message_template?.message !== "" && (
            <p
              dangerouslySetInnerHTML={{ __html: message_template?.message }}
              className="text-center message px-2 mb-3"
            />
          )}

          {/* <div className="scrollable-class "> */}

          {/* {message_template?.message !== "" && (
            <img
              src={message_template?.image}
              alt={message_template?.modal_title || ""}
              className="w-75 mx-auto mt-4 d-block"
            />
          )} */}
          <div className="text-center">
            {message_template?.action_url === null || message_template?.action_url === "" ? (
              <img
                src={message_template?.image}
                alt={message_template?.modal_title || ""}
                className={cn("mx-auto d-block image-style", {
                  "desktop-banner-img": breakpoint < width,
                })}
              />
            ) : (
              <img
                src={message_template?.image}
                alt={message_template?.modal_title || ""}
                className={cn("mx-auto d-block image-style cursor-pointer", {
                  "pt-0": message_template?.action !== null,
                  "desktop-banner-img": breakpoint < width,
                })}
                onClick={handleClick}
              />
            )}
          </div>

          {/* {message_template?.action_url && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <img
              src={message_template?.image}
              alt={message_template?.modal_title || ""}
              className={cn("image-style", {
                "pt-5 pe-3 ps-3": message_template?.action !== null,
              })}
              onClick={handleClick}
            />
          )} */}
        </div>

        {message_template?.action_url && message_template?.action && (
          <div
            className="d-flex justify-content-center py-3 campaign-message-modal-footer"
            style={{
              backgroundColor: message_template?.background_color,
            }}
          >
            <button
              css={button}
              onClick={handleClick}
              className="campaign-message-modal-footer-button"
              style={{
                backgroundColor: message_template?.action_button_color,
                color: message_template?.action_button_text_color,
              }}
            >
              {message_template ? message_template.action : ""}
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CampaignMessageModal;
