import { updateLocalQueryParams } from "utils";
import PaymentService from "..";

class GoamaNequiPaymentService extends PaymentService {
  public tournament: ITournament | undefined;
  public sdk: ISdkResource | null;
  mobileNumber: any; // Keep mobileNumber, but set it later

  constructor({ country, application, user, sdk, paymentReferenceCode = null }: any) {
    super({ country, application, user, paymentReferenceCode });
    this.sdk = sdk;
  }

  public async initiatePayment({
    tournament,
    mobileNumber,
    extraData = {},
  }: {
    tournament: any;
    mobileNumber: string;
    extraData?: any;
  }) {
    this.tournament = tournament;
    this.mobileNumber = mobileNumber; // Set mobileNumber here dynamically
    this.extraData = { ...extraData, phone_number: this.mobileNumber };

    return new Promise((resolve, reject) => {
      (async () => {
        this.baseURL = `${window.location.protocol}//${window.location.host}/tournament?tournament=${this.tournament?.id}`;
        const params = this.getParams();

        try {
          const response: any = await this.setPaymentReferenceCode({
            url: `init/${this.application?.slug}/`,
            params: params,
          });
          if (response.data?.new_userid) {
            updateLocalQueryParams({ userid: response.data.new_userid });
          }
          this.paymentInitializationResponse = response?.data;

          if (tournament?.entry_fee_type === "cash") {
            this.handlePostPaymentConfirmation();
          }

          resolve(response.data);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  public handlePostPaymentConfirmation() {
    if (this.sdk?.postPaymentConfirmationCallback) {
      this.sdk.postPaymentConfirmationCallback(this.paymentInitializationResponse);
    }
  }

  public getQueryParams() {
    return {
      country: this.country,
      callback: true,
      app: this.application?.slug,
      userid: this.user?.username?.split("__")[0],
      tournament: this.tournament?.id,
    };
  }

  protected getParams = () => {
    const queryParams = this.getQueryParams();
    const params = {
      amount: this.tournament?.entry_fee,
      tournament: this.tournament?.id,
      currency: this.tournament?.entry_fee_currency,
      phone_number: this.mobileNumber, // Ensure it's now dynamically set and passed here
      // redirect_link: this.generateRedirectURL(`tournament?tournament=${this.tournament?.id}`, queryParams),
      ...this.extraData,
    };

    return params;
  };
}

export default GoamaNequiPaymentService;
