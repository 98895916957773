import React from "react";
import { LoginPanel } from "@bedrock_org/passport";
import { CommonModal } from "components/feature-specific/Modals/CommonModal";
import root from "react-shadow";
import styles from "@bedrock_org/passport/dist/style.css?inline";
import { css } from "@emotion/react";
import { CloseButton } from "react-bootstrap";
import { useAppDispatch } from "redux/Store";
import { hideLoginPopup } from "redux/reducers/layoutReducer";
import LOGO from "assets/clients/go3/orange_horizontal.avif";

const OrangeLoginPopUp = () => {
  const dispatch = useAppDispatch();
  return (
    <CommonModal
      style={css`
        .modal-content {
          background: black !important;
          padding: 0;
        }
      `}
      show
      hideCrossIcon
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: end;
          padding-right: 1rem;
        `}
      >
        <CloseButton
          variant="white"
          onClick={() => {
            dispatch(hideLoginPopup());
          }}
        />
      </div>
      <root.div>
        <style type="text/css">{styles}</style>
        <LoginPanel
          pnlClass="bg-[#202020] text-white w-[380px] border-0 pb-0 shadow-none" //for the panel styling
          btnClass="w-full hover:bg-orange-500/30" //will apply for all button atm
          // logo="https://irp.cdn-website.com/e81c109a/dms3rep/multi/orange-logo-v1a-20240228.svg"
          logo={LOGO}
          //logo url
          title="Sign in to&nbsp;" //title of the panel
          logoAlt="Orange Web3" //alt text for the logo
          logoClass="ml-2 h-8 " //logo styling, example height width
        />
      </root.div>
    </CommonModal>
  );
};

export default OrangeLoginPopUp;
