// import PaymentService from "./index";

import { APP_SLUGS } from "constants/constants";
import store from "redux/Store";
import { isStringEqual } from "utils";
import { isTournamentTryBased } from "utils/tournamentUtils";
import GoamaPaymentService from "./goama/GoamaPaymentService";
import GoamaTryBasedPaymentService from "./goama/GoamaTryBasedPaymentService";
import MvolaPaymentService from "./MvolaPaymentService";
import OoredooPaymentService from "./OoredooPaymentService";
import GoamaNequiPaymentService from "./goama/GoamaNequiPaymentService";

class PaymentProvider {
  public tournament: ITournament | undefined;
  static _instance: any;
  constructor() {
    // eslint-disable-next-line no-underscore-dangle
    if (PaymentProvider._instance) {
      // eslint-disable-next-line no-underscore-dangle, no-constructor-return
      return PaymentProvider._instance;
      //   throw new Error("Configuration  can't be instantiated more than once.");
    }
    // eslint-disable-next-line no-underscore-dangle
    PaymentProvider._instance = this;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTournamentFeeType(tournament: any) {
    switch (this.tournament?.entry_fee_type) {
      case "coin":
        // return new LegalUser(params);
        break;
      case "ticket":
        break;
      default:
        // return new NaturalUser(params);
        break;
    }
  }

  getPaymentService(tournament: ITournament, params: any = {}) {
    const {
      common: { application, country, user },
      //Note: Need to add subscription reducer here
    } = store.getState();

    // Check if the application is "nequi" and return GoamaPaymentServiceTypeTwo
    if (application?.payment_channel?.payment_type === "NEQUI") {
      return new GoamaNequiPaymentService({ application, country, user, ...params });
    }

    if (isTournamentTryBased(tournament)) {
      return new GoamaTryBasedPaymentService({ application, country, user, ...params });
    } else if (isStringEqual(APP_SLUGS.OOREDOO, application?.slug)) {
      return new OoredooPaymentService({ application, country, user, ...params });
    } else if (isStringEqual(APP_SLUGS.MVOLA, application?.slug)) {
      return new MvolaPaymentService({ application, country, user, ...params });
    } else {
      return new GoamaPaymentService({ application, country, user, ...params });
    }
  }
}

export default new PaymentProvider();
