/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { rgba } from "polished";
import { useHistory } from "hooks/useHistory";
import EntryFee from "components/common/EntryFee";
import { FaBan } from "react-icons/fa";
import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import {
  OldPaymentModals,
  MiniProgramVerifyPaymentModal,
  PaymentErrorModal,
} from "components/feature-specific/payment/OldPaymentModals";
import PaymentModal from "components/feature-specific/payment/PaymentModal/PaymentModal";
import usePlayNowButton from "hooks/usePlayNowButton";
import AuthModal from "components/feature-specific/auth/AuthModal";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { MotionButton } from "components/common/Buttons/MotionButton";
import classNames from "classnames";
import { MINI_APP_SLUGS } from "constants/constants";
import { isTournamentEntryFeeVoucher, isTournamentTryAvailable, isTournamentTryBased } from "utils/tournamentUtils";
import VoucherPaymentModal from "components/feature-specific/payment/VoucherPaymentModal";
import analytics from "utils/analytics";
import Advertisement from "components/feature-specific/advertisement/Advertisement";
import useDirectPayment from "hooks/useDirectPayment";
import { hidePayment, selectedTournament, showPayment } from "redux/reducers/paymentReducer";
import {
  isBkash,
  isBonoxs,
  isDana,
  isFegg,
  isFoodpanda,
  isGcashAliplus,
  isGoamaPH,
  isKlook,
  isOoredoo,
  isTng,
} from "utils/applicationSlug";
import HeaderBiddingAdvertisement from "components/feature-specific/advertisement/HeaderBiddingAdvertisement";

const footerBottom = isBonoxs ? "6.875rem" : "10.313rem";

const footer = (theme: ITheme) => css`
  position: absolute;
  left: 0;
  bottom: ${footerBottom};
  width: 100%;
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: space-around;
  z-index: 9999999;

  @media screen and (min-width: 1022px) {
    bottom: 130px;
  }

  @media screen and (min-width: 720px) {
    bottom: 145px;
  }

  @media screen and (max-width: 320px) {
    bottom: 85px;
  }
  .btn-footer {
    border: none;
    color: ${theme.button.buttonTextColor};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    height: 53px;
    box-shadow: 2px 10px 10px 0px ${rgba(theme.button.primaryGradientStart, 0.3)};
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    );

    &:focus {
      outline: none;
      box-shadow: none;
    }

    @media screen and (min-width: 360px) {
      height: 46px;
      font-size: 15px;
    }

    @media screen and (max-width: 320px) {
      height: 40px;
      font-size: 12px;
    }
  }

  .btn-footer[disabled] {
    opacity: 0.8;
  }

  .not_dana_btn {
    border-radius: 15px;
    width: 73%;
  }

  .dana_btn {
    width: 80%;
    border-radius: 5px;
  }

  .footer-sticky-ranking-page {
    z-index: 999;
    position: fixed;
    left: 0;
    bottom: 88px;
    width: 100vw;
    margin-bottom: 12px;
  }
`;

const token = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  .token-radius {
    background: ${theme.background.primary};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    right: 50%;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .token-icon {
    width: 12px;
    height: 12px;
  }
`;

interface ITournamentDetailsFooterProps {
  game: IGame;
  tournament: ITournament;
  participant: IParticipant;
  playable: boolean;
}

export default function TournamentDetailsFooter({
  game,
  tournament,
  participant,
  playable,
}: ITournamentDetailsFooterProps) {
  const { search: queryParams } = useLocation();
  const { application, country, user } = useAppSelector((state: any) => state.common);
  const dispatch = useAppDispatch();
  const [showAuthModal, setAuthModal] = useState(false);
  const { paymentModalVisibility } = useAppSelector((state: any) => state.paymentState);

  const isFsecureEnabled = isTng && tournament?.entry_fee_type === "f-secure-sub";
  const directPaymentEnabled = isBkash || isOoredoo || isFsecureEnabled;
  const { progressShareModalVisibility } = useAppSelector((state: any) => state.gameplay);
  const [modalState, setModalState] = useState({
    payment: false,
    tokenError: false,
    tokenDeduct: false,
    paymentError: false,
    tokenVerification: false,
    verification: queryParams.includes("callback=true") && !directPaymentEnabled,
  });

  // const hideInsufficientBalanceModal = useCallback(() => {
  //   setInsufficientBalance(false);
  // }, []);

  const history = useHistory();
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
  const isLoggedin = Boolean(user && user?.id);

  const hideAuthModal = useCallback(() => {
    setAuthModal(false);
  }, []);

  useEffect(() => {
    dispatch(fetchTokenBalance(country));
  }, [country, dispatch]);



  // function checkBalance(tournament: any, e: any) {
  //   switch (tournament?.entry_fee_type) {
  //     case "coin":
  //       if (tournament?.entry_fee > balance.balance) {
  //         setInsufficientBalance(true);
  //       } else {
  //         showModal("tokenDeduct");
  //       }
  //       break;
  //     case "ticket":
  //       if (tournament?.entry_fee > balance.ticket_balance) {
  //         setInsufficientBalance(true);
  //       } else {
  //         showModal("tokenDeduct");
  //       }
  //       break;
  //     default:
  //       onPlayNowButtonClick(e);
  //       break;
  //   }
  // }

  const showModal = useCallback(
    (type: keyof typeof modalState) => {
      switch (type) {
        case "payment":
          return setModalState({ ...modalState, payment: true });
        case "paymentError":
          return setModalState({ ...modalState, paymentError: true });
        case "tokenDeduct":
          return setModalState({ ...modalState, tokenDeduct: true });
        case "tokenError":
          return setModalState({ ...modalState, tokenError: true });
        case "verification":
          return setModalState({ ...modalState, verification: true });
        case "tokenVerification":
          return setModalState({ ...modalState, verification: true });
      }
    },
    [modalState],
  );

  const hideModal = useCallback(
    (type: keyof typeof modalState) => {
      switch (type) {
        case "payment":
          return setModalState({ ...modalState, payment: false });
        case "paymentError":
          return setModalState({ ...modalState, paymentError: false });
        case "tokenDeduct":
          return setModalState({ ...modalState, tokenDeduct: false });
        case "tokenError":
          return setModalState({ ...modalState, tokenError: false });
        case "verification":
          return setModalState({ ...modalState, verification: false });
        case "tokenVerification":
          return setModalState({ ...modalState, tokenVerification: false });
      }
    },
    [modalState],
  );

  const partialUpdateModals = useCallback(
    (state: any) => {
      const currentState: any = { ...modalState };
      Object.keys(state).map((k: any) => (currentState[k] = state[k]));
      setModalState(currentState);
    },
    [modalState],
  );

  const { onPlayNowButtonClick, onPaymentConfirmationClick, registered, buttonLoading } = usePlayNowButton(
    tournament,
    game,
    {
      footer: {
        onClick: () => {
          if (tournament?.payment_reference && !directPaymentEnabled) {
            showModal("verification");
          } else {
            showModal("payment");
          }
        },
      },
    },
  );

  const isTournamentValid = () => {
    if (!playable || buttonLoading) return false;
    const { participant_count, max_participant } = tournament;
    if (max_participant > 0) {
      if (participant_count >= max_participant && !registered) {
        return false;
      }
    }
    return true;
  };

  const isButtonDisabled = !isTournamentValid();

  const { handleButtonLogic, showVoucherModal, setShowVoucherModal } = useDirectPayment({
    isButtonDisabled,
    tournament,
    onPlayNowButtonClick,
    onPaymentConfirmationClick,
    registered,
  });

  const getButtonText = function () {
    if (isButtonDisabled) {
      return <FaBan />;
    }
    // TODO: Need to refactor for user and guestUser loginpopup
    //______________________________________________________________
    // if (isGuestUser && tournament?.entry_fee_type !== "voucher") {
    /* if (isButtonDisabled) {
        return  <FaBan /> />;
      } */
    // }
    //______________________________________________________________

    if (buttonLoading) {
      return <LoadingIndicator size={2} color="#fff" />;
    } else if (isTournamentTryBased(tournament)) {
      // const textTry = getTournamentTryText(tournament);
      if (isTournamentTryAvailable(tournament)) {
        return isKlook ? (
          <Trans i18nKey="start">Start</Trans>
        ) : (
          <span>
            {tournament?.trial_balance}&nbsp;
            {tournament?.trial_balance === 1 ? <Trans i18nKey="try">try</Trans> : <Trans i18nKey="tries">tries</Trans>}
            &nbsp;
            {/* {getTournamentTryText(tournament)} */}
            <Trans i18nKey="left">left</Trans>
          </span>
        );
      } else {
        const renderTournamentInfo = () => {
          const trialPackage = tournament?.trial_packages?.[0];
          const trialValue = trialPackage?.trial_value;

          if (isTournamentEntryFeeVoucher(tournament)) {
            return (
              <>
                <Trans i18nKey="apply-code">Apply Code</Trans>
                &nbsp;<Trans i18nKey="for">for</Trans> {trialValue}&nbsp;
              </>
            );
          }

          if (isKlook) {
            return <Trans i18nKey="no-tries-left">No Tries Left</Trans>;
          }

          return (
            <>
              <Trans i18nKey="pay">Pay</Trans> &nbsp;
              <EntryFee
                entry_fee={trialPackage?.currency_value}
                foodpandaAppIconColor={isFoodpanda}
                payMethod={trialPackage?.currency}
                trialMethod
              />
              &nbsp;<Trans i18nKey="for">for</Trans> {trialValue}&nbsp;
            </>
          );
        };

        const renderTriesLeft = () => {
          const trialValue = tournament?.trial_packages?.[0]?.trial_value;
          if (trialValue === 1) {
            return <Trans i18nKey="try">try</Trans>;
          }
          return <Trans i18nKey="tries">tries</Trans>;
        };
        return (
          <span className="title-xs d-flex flex-row justify-content-center">
            {renderTournamentInfo()}
            {!isKlook && renderTriesLeft()}
          </span>
        );
      }
    } else if (tournament?.entry_fee_type === "voucher" && !registered) {
      return <Trans i18nKey="apply-coupon-co">Apply Coupon Code</Trans>;
    } else if (registered || (tournament?.entry_fee === 0 && tournament?.entry_fee_type === "voucher")) {
      if (application?.slug === "trendi") {
        return <Trans i18nKey="play-win">Play & Win</Trans>;
      } else {
        return <Trans i18nKey="play-now">Play</Trans>;
      }
    } else {
      const getPrefix = () => {
        if (isBkash) {
          return <Trans i18nKey="pay-now">Pay Now&nbsp;</Trans>;
        }
        if (isBonoxs) {
          return (
            <>
              <Trans i18nKey="pay-now">Pay Now</Trans>&nbsp;
            </>
          );
        }
      };

      const renderCoinEnabled = () => {
        return (
          <div css={token}>
            {getPrefix()}
            <EntryFee
              entry_fee={tournament?.entry_fee}
              payMethod={tournament?.entry_fee_type}
              foodpandaAppIconColor={isFoodpanda}
              tournamentFeeCurrency={tournament?.entry_fee_currency}
            />
          </div>
        );
      };

      const renderPaymentReference = () => {
        return <Trans i18nKey="verify-payment">Verify Payment</Trans>;
      };

      const renderDefaultPayment = () => {
        return (
          <div className="d-flex flex-row justify-content-center">
            {tournament?.entry_fee > 0 && getPrefix()}
            <EntryFee
              entry_fee={tournament?.entry_fee}
              payMethod={tournament?.entry_fee_type}
              tournamentFeeCurrency={tournament?.entry_fee_currency}
            />
          </div>
        );
      };
      const decideRendering = () => {
        if (application?.applicationsetting?.enable_coin) {
          return renderCoinEnabled();
        }
        if (tournament?.payment_reference) {
          return renderPaymentReference();
        }
        return renderDefaultPayment();
      };
      return <>{decideRendering()}</>;
    }
  };

  return (
    <>
      {!progressShareModalVisibility ? (
        <div
          css={footer}
          className="desktop-play-button-backward leaderboard-popup-footer"
          onClick={(e: any) => {
            if (isGoamaPH || isFegg) {
              if (!isGuestUser && !isLoggedin) {
                history.push("/login");
              }
            }
            /* if (isGoamaPH) {
              if (!isGuestUser && !isLoggedin){
                history.push("/login");
              }
            } */
            if (tournament) {
              dispatch(selectedTournament(tournament));
            }

            {
              /* Note: need to check  */
            }

            handleButtonLogic(e);
          }}
        >
          <MotionButton
            width={isDana ? "90%" : "74%"}
            // disabled={isButtonDisabled}
            customClassNames={classNames("d-block btn-footer desktop-play-footer ms-auto me-auto")}
            title={getButtonText()}
          />

          <div className="col-12 footer-sticky-ranking-page">
            {isOoredoo ? (
              <HeaderBiddingAdvertisement name="ooredoo-sticky-footer-ranking-page" />
            ) : isGcashAliplus ? (
              <HeaderBiddingAdvertisement name="gcashaliplus-sticky-footer-ranking-page" />
            ) : (
              <Advertisement name="Sticky Footer Ranking Page" />
            )}
          </div>
        </div>
      ) : null}

      {/* <ConfirmedPurchase
        tournament={tournament}
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}

      {MINI_APP_SLUGS.includes(application?.slug) && modalState.verification && (
        <MiniProgramVerifyPaymentModal
          tournament={tournament}
          show={modalState.verification}
          participant={participant}
          onHide={() => hideModal("verification")}
        />
      )}

      {/* {!["tng", "alipay-hk", "dana"].includes(application.slug) &&
        modalState.verification && (
          <VerifyPaymentModal
            tournament={tournament}
            show={modalState.verification}
            participant={participant}
            onHide={() => hideModal("verification")}
          />
        )} */}

      {(!participant || isTournamentTryBased(tournament)) && !directPaymentEnabled && (
        <>
          {/* -------------------------------- refactor -------------------------------- */}
          {MINI_APP_SLUGS.includes(application?.slug) && tournament?.entry_fee_type === "cash" ? (
            <OldPaymentModals
              show={modalState.payment}
              onHide={() => hideModal("payment")}
              partialUpdateModals={partialUpdateModals}
              tournament={tournament}
              game={game}
              amount={
                <EntryFee
                  entry_fee={tournament?.entry_fee}
                  payMethod={tournament?.entry_fee_type}
                  foodpandaAppIconColor={isFoodpanda}
                  tournamentFeeCurrency={tournament?.entry_fee_currency}
                />
              }
            />
          ) : (
            <PaymentModal
              show={paymentModalVisibility}
              onHide={() => {
                dispatch(hidePayment());
              }}
              onShow={() => dispatch(showPayment())}
              tournament={tournament}
              value={tournament?.entry_fee}
              amount={
                <EntryFee
                  entry_fee={tournament?.entry_fee}
                  payMethod={tournament?.entry_fee_type}
                  foodpandaAppIconColor={isFoodpanda}
                />
              }
            />
          )}
          {/* -------------------------------- refactor -------------------------------- */}

          <PaymentErrorModal
            show={modalState.paymentError}
            onHide={() => hideModal("paymentError")}
            onRetry={(e: any) => {
              hideModal("paymentError");
              analytics.setTournamentData(tournament);
              analytics.clickPlayNowLeaderboard();
              onPlayNowButtonClick(e);
            }}
          />
          <AuthModal show={showAuthModal} onHide={hideAuthModal} />
          {/* <BuyTokenModal
            show={showInsufficientBalance}
            onHide={hideInsufficientBalanceModal}
            pvpTicket={tournament?.entry_fee_type}
          /> */}
          {/*           
          <WalletTokenPaymentModal
            show={modalState.tokenDeduct}
            onHide={() => {
              hideModal("tokenDeduct");
            }}
            paymentType={tournament?.entry_fee_type}
            partialUpdateModals={partialUpdateModals}
            value={tournament?.entry_fee}
            tournament_id={tournament?.id}
            stateKey={"tokenDeduct"}
            stateVerificationKey={"tokenVerification"}
            stateErrorKey={"tokenError"}
          /> */}
          {/* -------------------------------- refactor -------------------------------- */}
          {/* <WalletTokenVerifyPaymentModal
            tournament={tournament}
            show={modalState.tokenVerification}
            onHide={() => {
              hideModal("tokenVerification");
              dispatch(fetchTokenBalance(country));
              dispatch(fetchUserTournaments(application?.slug, country));
            }}
            type="tournament"
          /> */}
          {/* -------------------------------- refactor -------------------------------- */}
          {showVoucherModal && (
            <VoucherPaymentModal
              tournament={tournament}
              show={showVoucherModal}
              onHide={() => {
                setShowVoucherModal(false);
              }}
              type="payment"
            />
          )}
        </>
      )}
    </>
  );
}
