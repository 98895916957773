import React from "react";
import { css, useTheme } from "@emotion/react";
import cn from "classnames";
import motion from "utils/polyFilledMotion";

import { rgba } from "polished";
import { useAppSelector } from "redux/Store";
import { APP_SLUGS } from "constants/constants";

export const MotionButton = ({
  disabled = false,
  onClick = () => {},
  title = "",
  customClassNames = "",
  customContainerClassNames = "",
  width = "100%",
  textColor = null,
  bgColor = null,
  borderColor = null,
}: {
  title: any;
  disabled?: boolean;
  customClassNames?: string;
  customContainerClassNames?: string;
  width?: string;
  onClick?: Function;
  bgColor?: string | null;
  textColor?: string | null;
  borderColor?: string | null;
}) => {
  const theme: ITheme = useTheme();
  const { application } = useAppSelector((state: any) => state.common);
  const hasGlow = () => {
    if (application?.slug === APP_SLUGS.RAZER) return true;
  };
  return (
    <div
      style={{
        width: width,
      }}
      className={cn({
        [customContainerClassNames]: true,
        "motion-button": true,
      })}
      css={baseCss}
    >
      <motion.button
        style={{
          color: `${textColor || theme.foreground.primary}`,
          background:
            bgColor ||
            `linear-gradient(
            270deg,
            ${theme.button.primaryGradientStart} 0%,
            ${theme.button.primaryGradientStop} 122.67%
          )`,
          boxShadow: `box-shadow: 2px 10px 10px 0px
          ${rgba(bgColor || theme.button.primaryGradientStart, 0.3)}`,
          borderColor: borderColor || "transparent",
          border: borderColor ? "1px solid" : "0",
        }}
        className={cn({
          [customClassNames]: true,
          "d-block btn-style": true,
          "has-glow": hasGlow(),
        })}
        whileTap={theme.layout === "TRIANGLE" && !disabled ? { scale: 0.9 } : { scale: 1 }}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </motion.button>
    </div>
  );
};

const baseCss = (theme: ITheme) => css`
  .btn-style {
    width: 100%;
    border-radius: 15px;
    border: none;
    /* color: ${theme.foreground.primary}; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    /* font-weight: 600; */
    height: 60px;
    /* background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    ); */
    /* box-shadow: 2px 10px 10px 0px
      ${rgba(theme.button.primaryGradientStart, 0.3)}; */
    &.has-glow {
      box-shadow: 0px 0px 0px 3px #44d62c;
    }

    &:focus {
      outline: none;
    }

    @media screen and (min-width: 360px) {
      height: 46px;
      font-size: 15px;
    }

    @media screen and (max-width: 320px) {
      height: 40px;
      font-size: 12px;
    }
  }

  .btn-style[disabled] {
    /* opacity: 0.8; */
    filter: invert(0.3);
  }

  .not_dana_btn {
    border-radius: 15px;
    width: 100%;
  }

  .dana_btn {
    width: 100%;
    border-radius: 5px;
  }
`;
