import { updateLocalQueryParams } from "utils";
import PaymentService from "..";

class GoamaPaymentService extends PaymentService {
  public tournament: ITournament | undefined;
  public sdk: ISdkResource | null;

  constructor({ country, application, user, sdk, paymentReferenceCode = null }: any) {
    super({ country, application, user, paymentReferenceCode });
    this.sdk = sdk;
  }

  public async initiatePayment({ tournament, extraData = {} }: { tournament: any; extraData?: any }) {
    this.tournament = tournament;
    this.extraData = extraData;
    return new Promise((resolve, reject) => {
      (async () => {
        this.baseURL = `${window.location.protocol}//${window.location.host}/tournament?tournament=${this.tournament?.id}`;
        const params = this.getParams();
    

        if (this.tournament?.entry_fee_type !== "cash") {
          delete params.redirect_link;
        }

        try {
          const response: any = await this.setPaymentReferenceCode({
            url: `init/${this.application?.slug}/`,
            params: params,
          });
          if (response.data?.new_userid) {
            updateLocalQueryParams({ userid: response.data.new_userid });
          }
          this.paymentInitializationResponse = response?.data;

          if (tournament?.entry_fee_type === "cash") {
            this.handlePostPaymentConfirmation();
          }

          resolve(response.data);
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  public handlePostPaymentConfirmation() {
    if (this.sdk?.postPaymentConfirmationCallback) {
      this.sdk.postPaymentConfirmationCallback(this.paymentInitializationResponse);
    }
  }

  public getQueryParams() {
    return {
      country: this.country,
      callback: true,
      app: this.application?.slug,
      userid: this.user?.username?.split("__")[0],
      tournament: this.tournament?.id,
    };
  }

  protected getParams = () => {
    const queryParams = this.getQueryParams();
    return {
      amount: this.tournament?.entry_fee,
      tournament: this.tournament?.id,
      currency: this.tournament?.entry_fee_currency,
      redirect_link: this.generateRedirectURL(`tournament?tournament=${this.tournament?.id}`, queryParams),
      ...this.extraData,
    };
  };
}

export default GoamaPaymentService;
