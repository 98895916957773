import { fetchTournamentDetails, fetchUserTournaments } from "actions";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { fetchStatusPoints } from "redux/reducers/statusPointReducer";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import LoadingIndicator from "components/common/LoadingIndicator/LoadingIndicator";
import { CommonModal } from "../../Modals/CommonModal";
import { css } from "@emotion/react";
import { changeMiniProgramPaymentVerifyModalStatus, IUIState, pvpSearchOpponent } from "redux/reducers/uiReducer";
import { useHistory } from "hooks/useHistory";
import Route from "HistoryWrapper";
import analytics from "utils/analytics";
import { isTournamentTryBased } from "utils/tournamentUtils";
import { hidePayment, resetPaymentState, updatePaymentStatus } from "redux/reducers/paymentReducer";
import { useNavigate } from "react-router";
import useQueryParams from "hooks/useQueryParams";
import {
  isBkash,
  isDana,
  isDanaAliplus,
  isGcash,
  isGcashAliplus,
  isGoogly,
  isJeda,
  isOoredoo,
  isPepsico,
  isTng,
  isTngAliplus,
} from "utils/applicationSlug";
import { fetchPartnerBalance } from "redux/reducers/partnerInfoBalanceReducer";
import FSecureSubscriptionModal from "../../Modals/FSecureModal/FSecureSubscriptionModal";
import { hidePlayGameButton } from "redux/reducers/layoutReducer";
import { toggleGameListPopup, togglePvPTournament } from "redux/reducers/modalReducer";
import { DynamicTranslation } from "components/common/DynamicTranslation";

export default function PaymentVerificationModal({
  tournament,
  show,
  paymentService,
  onHide,
  onSuccess = () => {},
}: any) {
  const { participants } = useAppSelector((state: any) => state.userTournaments);
  const isParticipant = () => {
    return participants?.find((p: IParticipant) => p.tournament === tournament?.id);
  };
  const { paymentStatus } = useAppSelector((state: any) => state.paymentState);
  const [showFsecureModal, setShowFsecureModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { application, country } = useAppSelector((state: any) => state.common);
  const directPaymentEnabled = isBkash || isOoredoo; // NOTE: In future add more checks like isBkash || isOredoo

  const isFsecureEnabled = isTng && tournament?.entry_fee_type === "f-secure-sub";

  /* ------------------------------- miniprogram ------------------------------ */
  const { miniAppPaymentVerifyModalStatus: miniPaymentStatus }: IUIState = useAppSelector((state: any) => state.ui);

  const queryParams = useQueryParams();
  const callback: any = queryParams.get("callback");

  const isMiniPrograms = isDana || isDanaAliplus || isGcash || isGcashAliplus || isTngAliplus || isTng || isJeda;

  /* ------------------------------- miniprogram ------------------------------ */

  const history = useHistory();
  const navigate = useNavigate();

  const verificationCheckingStates = () => {
    if (tournament?.tournament_type === "instant-pvp") {
      return "checking";
    } else if (!isTournamentTryBased(tournament) && isParticipant()) {
      if (directPaymentEnabled && tournament?.entry_fee_type === "cash") {
        navigate("/tournament");
        setTimeout(() => {
          navigate(`/tournaments/${tournament?.id}/play`);
          dispatch(resetPaymentState());
        }, 500);
      }
      if (tournament?.entry_fee_type === "coin") {
        dispatch(fetchTokenBalance(country)); //coin
      }

      return "success";
    } else {
      return "checking";
    }
  };

  const [modalState, setModalState] = useState<
    "checking" | "cancelled" | "failed" | "expired" | "error" | "success" | "done" | "cancelling" | "waiting"
  >(verificationCheckingStates());

  const [message, setMessage] = useState("");

  const hideVerificationModal = !(
    isDana &&
    (tournament?.entry_fee_type === "ticket" || tournament?.entry_fee_type === "coin")
  );

  useEffect(() => {
    if (modalState === "checking" || miniPaymentStatus === "checking") {
      setIsLoading(true);
      verifyPayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState, miniPaymentStatus]);

  function closeModal() {
    if (directPaymentEnabled) {
      Route.replace({ search: "" });
      navigate("/tournament");
      dispatch(resetPaymentState());
    }

    dispatch(fetchStatusPoints()) // ticket
      .finally(() => {
        if (application?.applicationsetting?.enable_partner_balance) {
          dispatch(fetchPartnerBalance(application?.slug)).finally(() => {
            setIsLoading(false);
            onHide();
          });
        } else {
          setIsLoading(false);
          onHide();
        }
      });
  }

  function verifyPayment() {
    const paymentTypeNequi = application?.payment_channel?.payment_type === "NEQUI";
    const numberOfTimesVerifyApiHit = paymentTypeNequi ? 10 : 5;
    const totalTime = paymentTypeNequi ? 2000 : 6000;
    paymentService
      ?.verifyPayment(numberOfTimesVerifyApiHit, totalTime)
      .then(() => {
        if (tournament?.tournament_type === "regular") {
          dispatch(fetchUserTournaments(application?.slug, country) as any).then(() => {
            if (tournament?.id && callback && directPaymentEnabled) {
              dispatch(resetPaymentState());
              Route.replace({ search: "" });
              navigate(`/tournaments/${tournament?.id}/play`);
            } else {
              if (directPaymentEnabled || isFsecureEnabled) {
                dispatch(toggleGameListPopup());
                dispatch(hidePayment());
                dispatch(updatePaymentStatus(null));
                dispatch(hidePlayGameButton());
                navigate(`/tournaments/${tournament?.id}/play`);
              }
              // dispatch(hidePayment());
              dispatch(fetchTournamentDetails(tournament?.id));
              setModalState("success");
              dispatch(updatePaymentStatus("success"));
            }
          });
        } else {
          setModalState("success");
        }
        /* ------------------------------- miniprogram ------------------------------ */
        if (isMiniPrograms) {
          dispatch(changeMiniProgramPaymentVerifyModalStatus("success"));
        }
        /* ------------------------------- miniprogram ------------------------------ */

        analytics.paymentCompleted(tournament.entry_fee);
        analytics.logRevenue(
          tournament?.id,
          tournament?.entry_fee,
          application?.payment_channel?.currency || "Unknown",
        );
      })
      .catch((e: any) => {
        const { status, reason } = e;

        if (status === "cancel") {
          setModalState("cancelled");
          dispatch(updatePaymentStatus("cancelled"));
          /* ------------------------------- start: miniprogram ------------------------------ */
          if (isMiniPrograms) {
            dispatch(changeMiniProgramPaymentVerifyModalStatus("cancelled"));
          }
          /* ------------------------------- end: miniprogram ------------------------------ */
          if (reason) setMessage(reason);
        } else if (status === "failed") {
          dispatch(updatePaymentStatus("failed"));
          if (isFsecureEnabled) {
            setShowFsecureModal(true);
          } else {
            setModalState("failed");
          }
          /* ------------------------------- start: miniprogram ------------------------------ */
          if (isMiniPrograms) {
            dispatch(changeMiniProgramPaymentVerifyModalStatus("failed"));
          }
          /* ------------------------------- end: miniprogram ------------------------------ */
          if (reason) setMessage(reason);
        } else if (status === "expired") {
          dispatch(updatePaymentStatus("expired"));
          setModalState("expired");
        } else {
          dispatch(updatePaymentStatus("error"));
          if (isFsecureEnabled) {
            setShowFsecureModal(true);
          } else {
            setModalState("error");
          }

          /* ------------------------------- start: miniprogram ------------------------------ */
          if (isMiniPrograms) {
            dispatch(changeMiniProgramPaymentVerifyModalStatus("error"));
          }
          /* ------------------------------- end: miniprogram ------------------------------ */
        }
      });
  }

  useEffect(() => {
    return () => {
      if (isMiniPrograms) {
        dispatch(changeMiniProgramPaymentVerifyModalStatus("waiting"));
      }
    };
    // eslint-disable-next-line
  }, []);

  function gotoPvpGame() {
    dispatch<any>(fetchUserTournaments(application?.slug, country)).then(() => {
      const url = window.location.href;
      if (url.includes("pvp-page")) {
        closeModal();
        dispatch(togglePvPTournament());
        dispatch(toggleGameListPopup());
        dispatch(pvpSearchOpponent());
      } else {
        dispatch(togglePvPTournament());
        dispatch(toggleGameListPopup());
        history.push(`/pvp-page/${tournament?.id}`);
      }
    });
  }

  function getModalTitle() {
    switch (modalState || miniPaymentStatus) {
      case "failed":
      case "error":
        return <Trans i18nKey="payment-failed!">Payment Failed!</Trans>;
      case "expired":
        return <Trans i18nKey="payment-expired!">Payment Expired!</Trans>;
      case "cancelling":
        return <Trans i18nKey="cancelling-paym">Cancelling Payment</Trans>;
      case "cancelled":
        return <Trans i18nKey="payment-cancell">Payment Cancelled</Trans>;
      case "success":
        return <Trans i18nKey="payment-success">Payment Successful!</Trans>;
      case "waiting":
        return <Trans i18nKey="waiting">Waiting</Trans>;
      default:
        return <Trans i18nKey="verifying-payme">Verifying Payment</Trans>;
    }
  }

  function getModalBody() {
    const commonErrorMessage = (
      <Trans i18nKey="your-payment-co">
        {!isGoogly ? "Your Payment could not be verified." : "Insufficient balance in wallet, please top up."}
      </Trans>
    );

    switch (modalState || miniPaymentStatus) {
      case "error":
        return commonErrorMessage;
      case "failed":
        return (message && <DynamicTranslation text={message} />) || commonErrorMessage;
      case "expired":
        return <Trans i18nKey="expired-payment">Your Payment Expired</Trans>;
      case "cancelling":
        return <Trans i18nKey="cancelling-your">Cancelling your payment. Please Wait.</Trans>;
      case "cancelled":
        return message || <Trans i18nKey="your-payment-ca">Your Payment was cancelled.</Trans>;
      case "success":
        return <Trans i18nKey="your-payment-su">Your payment was successful.</Trans>;
      case "waiting":
        return <Trans i18nKey="waiting-for-pay">Waiting for Payment</Trans>;
      default:
        return <Trans i18nKey="verifying-your-">Verifying your payment. Please Wait.</Trans>;
    }
  }

  return (
    <>
      <CommonModal
        show={show && !isFsecureEnabled && hideVerificationModal}
        onHide={closeModal}
        header={
          <Trans i18nKey="payment-verify">
            <b>{getModalTitle()}</b>
          </Trans>
        }
        footer={
          <div>
            {(modalState || miniPaymentStatus) !== "cancelled" &&
              (modalState || miniPaymentStatus) !== "failed" &&
              (modalState || miniPaymentStatus) !== "expired" &&
              (modalState || miniPaymentStatus) !== "success" &&
              !(modalState === "checking" && isPepsico) && (
                <Button
                  onClick={() => {
                    if (isMiniPrograms) {
                      dispatch(changeMiniProgramPaymentVerifyModalStatus("checking"));
                    }
                    setModalState("checking");
                    dispatch(updatePaymentStatus("checking"));
                    verifyPayment();
                  }}
                  css={buttonStyle}
                  className="me-2 verify-btn"
                  disabled={(modalState || miniPaymentStatus) === "checking"}
                >
                  {(modalState || miniPaymentStatus) === "checking" && <LoadingIndicator size={1.4} color="#fff" />}
                  {(modalState || miniPaymentStatus) === "error" && <Trans i18nKey="try-again">Try Again</Trans>}
                </Button>
              )}

            {!(modalState === "checking" && isPepsico) && (
              <Button
                css={buttonStyle}
                onClick={() => {
                  if (
                    tournament?.tournament_type !== "instant-pvp" &&
                    (modalState || miniPaymentStatus) === "success"
                  ) {
                    if (isTournamentTryBased(tournament)) {
                      onSuccess();
                    }
                    if (directPaymentEnabled) {
                      navigate(`/tournaments/${tournament?.id}/play`);
                      dispatch(resetPaymentState());
                    }
                    dispatch(hidePayment());
                    closeModal();
                  } else if (
                    tournament?.tournament_type === "instant-pvp" &&
                    (modalState || miniPaymentStatus) === "success"
                  ) {
                    gotoPvpGame();
                    dispatch(resetPaymentState());
                  } else {
                    closeModal();
                  }
                }}
                disabled={(modalState || miniPaymentStatus) === "checking"}
                className="cancel-btn"
              >
                {(modalState || miniPaymentStatus) === "success" ? (
                  <div className="okay-btn">
                    <Trans i18nKey="okay">Okay</Trans>
                  </div>
                ) : (
                  <div>
                    <Trans i18nKey="cancel">Cancel</Trans>
                  </div>
                )}
              </Button>
            )}
          </div>
        }
      >
        <div className="text-center title-md">
          <div className="d-flex col-12 p-0">{getModalBody()}</div>
        </div>
      </CommonModal>
      {isLoading && (
        <div css={loaderStyle}>
          <LoadingIndicator size={2} color="#118EEA" />
        </div>
      )}
      {showFsecureModal && (
        <FSecureSubscriptionModal
          show={showFsecureModal}
          onHide={() => {
            setShowFsecureModal(false);
          }}
        />
      )}
    </>
  );
}

const buttonStyle = (theme: ITheme) => css`
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  background: linear-gradient(
    270deg,
    ${theme.button.primaryGradientStart} 0%,
    ${theme.button.primaryGradientStop} 122.67%
  );

  &:hover {
    text-decoration: none;
    color: white;
  }
`;

const loaderStyle = css`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
