import { AppDispatch } from "redux/Store";
import { isStringEqual, decodeDataFromURL } from "utils";
// import authAPI from "./api/old_auth";
// import eventsAPI from "./api/old_events";
// import gamedataAPI from "./api/old_gamedata";
// import paymentsAPI from "./api/old_payments";
// import subscriptionAPI from "actions/api/old_subscription";
// import tournamentsAPI from "./api/old_tournaments";
// import statusAPI from "actions/api/old_status";
// import { updateAxiosInstanceAPIToken } from "./api/utils";
// import balanceAPI from "actions/api/old_balance";
import GameTimer from "utils/gameTimer";
// import referApi from "actions/referralApi";
import { EL_TIEMPO_USERID_KEY, SABAY_USERID_KEY, APP_SLUGS } from "constants/constants";
// import monopolyAPI from "./api/old_monopoly";
// import biReportsAPI from "./api/biReportsApi";
// import initApi from "./api/old_initApi";
import {
  isMok,
  isGcashAliplus,
  isMpt4u,
  isNequi,
  isGcash,
  isDana,
  isGlobe,
  isJeda,
  isPepsico,
  isSabay,
  isElTiempo,
  isMemePop,
  isPaobc,
  isGo3,
} from "utils/applicationSlug";
// import rewardsAPI from "./api/old_rewards";
// import wheelApi from "actions/api/old_wheelApi";
// import ticketApi from "routes/tickets/ticketsApi";
import api from "./api";
import {
  entryLogComplete,
  fetchArcadeSuccess,
  fetchCampaignSuccess,
  fetchCommonDataError,
  fetchCommonDataLoading,
  fetchCommonDataOutdatedError,
  fetchCommonDataSuccess,
  fetchCommonDataUnauthorizedError,
  fetchContactUsSuccess,
  fetchFaqSuccess,
  fetchPrivacyPolicySuccess,
  fetchTermsAndConditionsSuccess,
  updateQueryParams,
} from "redux/reducers/commonReducer";
import { fetchCommonDataSuccess as fetchCommonDataSuccessFromLayout } from "redux/reducers/layoutReducer";

export const fetchCommonData =
  (
    {
      app_slug,
      country,
      name,
      profile_pic,
      userid,
      uuid = null,
      identity_id = null,
      old_user = null,
      showAds,
      token,
      uri_ref,
      partner_id,
      walletId,
      loyaltyCardNumber,
      extra_data = null,
    } = decodeDataFromURL(),
    preInitCallback?: Function,
  ) =>
  async (dispatch: AppDispatch) => {
    const timer = new GameTimer();
    timer.start();
    dispatch(fetchCommonDataLoading());
    const postData: any = {
      app_slug,
      country: country && country.replace(/[^a-zA-Z ]/g, ""),
      userid,
    };

    if (isSabay && localStorage.getItem(SABAY_USERID_KEY)) {
      postData.userid = localStorage.getItem(SABAY_USERID_KEY);
    } else if (isElTiempo && localStorage.getItem(EL_TIEMPO_USERID_KEY)) {
      postData.userid = localStorage.getItem(EL_TIEMPO_USERID_KEY);
    }

    // if (isPepsico) postData.userid = uuid;

    if (uri_ref) postData.uri_ref = uri_ref;
    if (name) postData.name = name;
    if (extra_data) postData.extra_data = extra_data;
    if (profile_pic) postData.profile_pic = encodeURI(profile_pic);
    if (partner_id) {
      postData.partner_id = partner_id;
      localStorage && localStorage.setItem("ad_partner_id", partner_id);
    }

    if (isMok) {
      postData.walletId = walletId;
      postData.loyaltyCardNumber = loyaltyCardNumber;
    }

    if (preInitCallback) {
      const callbackData = await preInitCallback();
      if (callbackData.userid) postData.userid = callbackData.userid;
      if (callbackData.name) postData.name = callbackData.name;
      if (callbackData.profile_pic) postData.profile_pic = callbackData.profile_pic;
    }

    if (!postData.userid) {
      let user: any = null;
      let remembered: boolean | null = null;
      try {
        remembered = localStorage && !!(localStorage.getItem("rem") as string);
        if (remembered) {
          user = localStorage && JSON.parse(localStorage.getItem("user") as string);
        } else {
          user = sessionStorage && JSON.parse(sessionStorage.getItem("user") as string);
        }
        const { username } = user;

        postData.userid = username;

        if (isStringEqual(postData?.app_slug, APP_SLUGS.SKIBIDI_TOILET)) {
          postData.name = user?.first_name;
        }
      } catch (e) {
        delete postData["userid"];
      }
    }

    if (isMpt4u && !postData.userid) {
      return dispatch(fetchCommonDataOutdatedError());
    }

    if (isGcashAliplus) {
      if (old_user && old_user !== "null" && old_user !== "undefined") {
        postData.old_user = old_user;
      }
    } else if (isPepsico) {
      // NOTE: Uncomment this when its time to deploy pepsico wip/joy-2 changes
      if (identity_id && identity_id !== "null" && identity_id !== "undefined") {
        postData.old_user = identity_id;
      }
      if (uuid && uuid !== "null" && uuid !== "undefined") {
        postData.userid = uuid;
      } else {
        delete postData.userid;
      }
    }

    /** BEGIN: NEQUI specific logic */
    if (isNequi && token) {
      postData.userid = token;
    }
    /** END: NEQUI specific logic */

    let axiosOptions: any =
      isDana || isGcash || isGcashAliplus || isGlobe || isJeda || isMemePop() ? { timeout: 20000 } : {};
    if (isPaobc()) {
      axiosOptions = { timeout: 10000 };
    }
    // if (isGo3) {
    //   try {
    //     const initToken = localStorage.getItem("init-token");
    //     if (initToken) {
    //       axiosOptions = {
    //         ...axiosOptions,
    //         headers: {
    //           Authorization: `Bearer ${initToken}`,
    //         },
    //       };
    //     }
    //   } catch (e) {}
    // }
    if (isGo3) {
      try {
        const user: any = JSON.parse(localStorage.getItem("user") || "");
        if (user?.username && !user?.extra_data?.guest) {
          postData.userid = user?.username;
        }
      } catch (e) {}
    }

    /* if (!postData?.userid) {
      await checkSessionAndUpdateAxiosInstance(initApi);
    } */

    return api
      .post("tournaments/init/", postData, axiosOptions)
      .then(response => {
        timer.stop();
        const { data } = response;

        const apiResponseTime = timer.duration;
        // Save params into localStorage on successful request
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        dispatch(updateQueryParams(url.search));
        if (url.search && params.get("country") && params.get("app")) {
          localStorage && localStorage.setItem("query_params", url.search);
        }
        const token = data?.user?.token?.token;
        if (token) {
          sessionStorage && sessionStorage.setItem("init-token", token);
          localStorage && localStorage.setItem("init-token", token);
          localStorage && localStorage.setItem("user", JSON.stringify(data?.user));
          /* updateAxiosInstanceAPIToken(
            [
              tournamentsAPI,
              rewardsAPI,
              authAPI,
              paymentsAPI,
              statusAPI,
              balanceAPI,
              eventsAPI,
              gamedataAPI,
              referApi,
              wheelApi,
              ticketApi,
              monopolyAPI,
              // biReportsAPI,
              subscriptionAPI,
            ],
            token,
          ); */
        }

        dispatch(fetchCommonDataSuccess({ ...data, apiResponseTime, showAds }));
        dispatch(fetchCommonDataSuccessFromLayout({ user: data?.user }));
        return data;
      })
      .then(data => {
        api
          .post("tournaments/entry-log/", postData)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            dispatch(entryLogComplete());
          });
        return data;
      })
      .catch(e => {
        if (e?.response?.status === 401) {
          console.log("TOKEN EXPIRED");
          const redirectUrl = e.response.data?.redirect_url;
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else if (window.Cortex) {
            window.Cortex?.dismissWebView();
          } else {
            dispatch(fetchCommonDataUnauthorizedError());
          }
        } else {
          dispatch(fetchCommonDataError());
        }
      });
  };

export const fetchArcade = (appSlug: string, country: string) => (dispatch: any) => {
  api.get(`tournaments/arcade-games/config/${appSlug}/${country}/`).then(res => {
    dispatch(fetchArcadeSuccess(res.data));
  });
};

export const fetchFAQ = () => (dispatch: AppDispatch, getState: Function) => {
  // dispatch({ type: ActionTypes.FETCH_FAQ_LOADING });
  const { country, application } = getState().common;
  return api.get(`tournaments/faqs/${application?.slug}/${country}/`).then(response => {
    dispatch(fetchFaqSuccess(response.data));
    return response.data;
  });
  // .catch(() => dispatch({ type: ActionTypes.FETCH_FAQ_ERROR }));
};

export const fetchTermsAndConditions = () => (dispatch: AppDispatch, getState: Function) => {
  // dispatch({ type: ActionTypes.FETCH_T_AND_C_LOADING });
  const { country, application } = getState().common;
  return api.get(`tournaments/t&c/${application?.slug}/${country}/`).then(response => {
    dispatch(fetchTermsAndConditionsSuccess(response.data));
    return response.data;
  });
  // .catch(() => dispatch({ type: ActionTypes.FETCH_T_AND_C_ERROR }));
};

export const fetchContactUS = () => (dispatch: AppDispatch, getState: Function) => {
  // dispatch({ type: ActionTypes.FETCH_CONTACT_US_LOADING });
  const { country, application } = getState().common;
  return api.get(`tournaments/contact-us/${application?.slug}/${country}/`).then(response => {
    dispatch(fetchContactUsSuccess(response.data));
    return response.data;
  });
  // .catch(() => dispatch({ type: ActionTypes.FETCH_CONTACT_US_ERROR }));
};

export const fetchPrivacyPolicy = () => (dispatch: AppDispatch, getState: Function) => {
  // dispatch({ type: ActionTypes.FETCH_PRIVACY_POLICY_LOADING });
  const { country, application } = getState().common;
  return api.get(`tournaments/privacy-policy/${application?.slug}/${country}/`).then(response => {
    dispatch(fetchPrivacyPolicySuccess(response.data));
    return response.data;
  });
  // .catch(() => dispatch({ type: ActionTypes.FETCH_PRIVACY_POLICY_ERROR }));
};

export const fetchCampaign = () => (dispatch: AppDispatch) => {
  // dispatch({ type: ActionTypes.FETCH_CAMPAIGN_LOADING });
  return api.get(`tournaments/campaigns/`).then(response => {
    dispatch(fetchCampaignSuccess(response.data));
    return response.data;
  });
  // .catch(e => dispatch({ type: ActionTypes.FETCH_CAMPAIGN_ERROR }));
};
