import React, { useContext, useEffect } from "react";
import { css, useTheme } from "@emotion/react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { lighten } from "polished";
import { useHistory } from "hooks/useHistory";
import DesktopNavBarRight from "./NavBarRight";
import TicketStatus from "routes/tickets/components/TicketStatus";
import { LogOutUser } from "redux/reducers/userLoginReducer";
import { decodeDataFromURL, isStringEqual } from "utils";
import { showLoginPopup } from "actions";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { SdkContext } from "providers/client-sdk-providers";
import { APP_SLUGS, EL_TIEMPO_USERID_KEY, SABAY_USERID_KEY } from "constants/constants";
import { fetchStatusPoints } from "redux/reducers/statusPointReducer";
import { Trans } from "react-i18next";
import GizerBoostStatus from "assets/clients/Gizer/GizerBoostStatus";
import ButtonWithIcon from "components/common/Buttons/ButtonWithIcon";
import PartnerBalanceStatus from "routes/partner/components/PartnerBalanceStatus";
import useMetaTracker, { MetaTrackerTypes } from "hooks/useMetaTracker";
import {
  isBonoxs,
  isBubbaloo,
  isCroydon,
  isDetikCom,
  isFoodpanda,
  isMetaVerseGo,
  isPulzo,
  isSabay,
  isTourEast,
} from "utils/applicationSlug";
import { BiSolidPencil, BiSolidLogInCircle } from "react-icons/bi";
import Navbar from "components/common/Navbar/Navbar";
import { useLocation } from "react-router-dom";

const getLogo = (logo: string, alt = "logo", link = "") => {
  return logo ? (
    <img
      onClick={() => {
        if (link) window.location.href = link;
      }}
      src={logo}
      alt={alt}
      className={classNames({ "cursor-pointer": !!link })}
      css={css`
        width: auto;
        height: auto;
        max-height: 55px;
        z-index: 1;
        position: relative;
      `}
    />
  ) : null;
};

const DesktopNavbar = () => {
  const sdk = useContext(SdkContext);
  const { isDesktop } = useAppSelector((state: any) => state.layout);
  const { getTokenStatusBar = null, getTicketStatusBar = null } = sdk;
  const { application, user, country } = useAppSelector((state: any) => state.common);
  const { userid } = decodeDataFromURL();
  const { instance } = useContext(SdkContext);
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;
  const showLogout = !isGuestUser && !userid;
  const logoPosLeft = true;
  const history: any = useHistory();
  const dispatch = useAppDispatch();
  const urlElements = window.location.href.split("/");
  const currentPath = urlElements[3];
  const metaTracker = useMetaTracker();
  const theme: ITheme = useTheme();

  const handleClick = () => {
    if (
      isStringEqual(application?.slug, APP_SLUGS.SABAY) ||
      isStringEqual(application?.slug, APP_SLUGS.SOLEPACK) ||
      isStringEqual(application?.slug, APP_SLUGS.FOODPANDA) ||
      isStringEqual(application?.slug, APP_SLUGS.TRENDI) ||
      isStringEqual(application?.slug, APP_SLUGS.CHOPE_STORMS) ||
      isStringEqual(application?.slug, APP_SLUGS.SKIBIDI_TOILET) ||
      isStringEqual(application?.slug, APP_SLUGS.XCTUALITY)
    ) {
      dispatch(showLoginPopup());
    } else {
      metaTracker(MetaTrackerTypes.INITIATE_CHECKOUT);
      history.push(`/login`);
    }
  };

  const handleLogout = () => {
    if (application?.slug?.toLowerCase() === APP_SLUGS.SABAY?.toLowerCase()) {
      localStorage.removeItem(SABAY_USERID_KEY);
      instance
        .logout(false)
        .then()
        .catch()
        .finally(() => dispatch(LogOutUser()));
    } else if (application?.slug?.toLowerCase() === APP_SLUGS.EL_TIEMPO?.toLowerCase()) {
      localStorage.removeItem(EL_TIEMPO_USERID_KEY);
      dispatch(LogOutUser());
    } else {
      dispatch(LogOutUser());
      window.location.reload();
    }
  };

  const handleClickSignup = () => {
    if (
      isStringEqual(application?.slug, APP_SLUGS.TRENDI) ||
      isStringEqual(application?.slug, APP_SLUGS.PRESENTE) ||
      isStringEqual(application?.slug, APP_SLUGS.CHOPE_STORMS) ||
      isStringEqual(application?.slug, APP_SLUGS.SKIBIDI_TOILET) ||
      isStringEqual(application?.slug, APP_SLUGS.XCTUALITY)
    ) {
      dispatch(showLoginPopup());
    } else if (isMetaVerseGo) {
      metaTracker(MetaTrackerTypes.INITIATE_CHECKOUT);
      history.push(`/login`);
    } else {
      metaTracker(MetaTrackerTypes.SUBMIT_APPLICATION);
      history.push(`/signup`);
    }
  };

  useEffect(() => {
    dispatch(fetchStatusPoints());
    dispatch(fetchTokenBalance(country));
  }, [country, dispatch]);

  const getTokenStatus = () => {
    if (getTokenStatusBar) {
      return getTokenStatusBar();
    } else if (application?.slug?.toLowerCase() === APP_SLUGS.GIZER) {
      return <GizerBoostStatus plusButton={false} />;
    } else if (application?.applicationsetting?.enable_coin && user && !application?.enable_top_bar) {
      return <ButtonWithIcon plusButton={true} textColor="#fff" iconColor="#fff" />;
    }
  };

  const getTicketStatus = () => {
    if (getTicketStatusBar) {
      return getTicketStatusBar();
    } else if (
      application?.applicationsetting?.enable_user_journey === true &&
      application?.applicationsetting?.enable_journey_country === true &&
      user
    ) {
      return <TicketStatus plusButton={true} />;
    }
  };

  const getPartnerBalanceStatus = () => {
    const { enable_partner_balance } = application.applicationsetting;

    if (enable_partner_balance && user) return <PartnerBalanceStatus plusButton={true} />;
  };

  const { pathname } = useLocation();

  const isHome = pathname === "/" || pathname === "/tournament";

  const handleOnBack = () => {
    if (isTourEast) window.location.href = "https://app-toureast.xctualyfe.com";
    else if (isBonoxs && isHome) window.location.href = "https://bonoxs.com";
    else history.goBack();
  };

  return (
    <div css={baseCss}>
      <div className="top-bar-container">
        <div
          css={css`
            height: 100%;
          `}
          className={classNames(" d-flex flex-row justify-content-between", {
            "fixed-height": !logoPosLeft,
          })}
        >
          <div className="top-bar-left">
            <Navbar
              backButtonEnabled={application?.is_back_button_enabled}
              backButtonColor={theme.background.secondary}
              onBack={handleOnBack}
            />
            <div
              className={isDesktop && application?.is_back_button_enabled ? "logo-container-desktop" : "logo-container"}
            >
              {logoPosLeft && (
                <>
                  {getLogo(application?.goama_logo, "goama")}
                  {application?.enable_partner_logo_desktop &&
                    getLogo(application?.partner_logo, "partner-logo", application?.partner_logo_link)}
                </>
              )}
            </div>
            <div className="right avatar-col">
              {!logoPosLeft && (
                <>
                  {getLogo(application?.goama_logo, "goama")}
                  {getLogo(application?.partner_logo, "partner-logo", application?.partner_logo_link)}
                </>
              )}
              {(!user || isGuestUser) && (
                <>
                  {!isSabay && !isFoodpanda && !isPulzo && !isBubbaloo && !isDetikCom && (
                    <div className="auth-btn">
                      <Button
                        className={currentPath === "signup" ? "login-active" : "button button-link"}
                        variant="outline-secondary"
                        onClick={handleClickSignup}
                      >
                        {isCroydon && <BiSolidPencil className="me-1" />}
                        <Trans i18nKey="sign-up">Sign Up</Trans>
                      </Button>
                    </div>
                  )}

                  {!isPulzo && !isDetikCom && (
                    <div className="auth-btn">
                      <Button
                        className={currentPath === "login" ? "login-active" : "button button-link"}
                        variant="link"
                        onClick={handleClick}
                      >
                        {isCroydon && <BiSolidLogInCircle className="me-1" />}
                        <Trans i18nKey="login">Login</Trans>
                      </Button>
                    </div>
                  )}
                </>
              )}
              {user && (
                <>
                  <div className="d-flex flex-row">
                    <div className="me-2">{getTokenStatus()}</div>
                    <div>{getTicketStatus()}</div>
                    <div>{getPartnerBalanceStatus()}</div>
                  </div>
                  {showLogout && (
                    <div className="auth-btn">
                      <Button className="button" variant="outline-secondary" onClick={handleLogout}>
                        <Trans i18nKey="log-out">Log Out</Trans>
                      </Button>
                    </div>
                  )}

                  <DesktopNavBarRight />
                </>
              )}
            </div>
          </div>
          {/* <div className="text-right top-bar-right"></div> */}
        </div>
      </div>
    </div>
  );
};

const baseCss = (theme: ITheme) => css`
  .auth-btn {
    min-width: 90px;
  }
  .top-bar-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    .fixed-height {
      min-height: 80px;
    }
  }
  .top-bar-left {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 100%;
    z-index: 999;
    .logo-container {
      margin-left: 10px;
      > :not(:last-of-type) {
        margin-right: 10px;
        margin-left: 5px;
      }
    }

    .logo-container-desktop {
      margin-left: calc(100vw - 60%);
      > :not(:last-of-type) {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      justify-content: flex-end;
      //width: 760px !important;
      //margin-top: 1rem;
      flex-grow: 1;
      > :not(:last-child) {
        margin-right: 10px;
      }
    }
    margin-right: 2.5% !important;
    @media (min-width: 1022px) {
      /* width: 150vw !important; */
      /* margin-right: 1% !important; */
    }

    @media (min-width: 1124px) {
      /* width: 160vw !important; */
      /* margin-right: 5% !important; */
    }
  }
  .top-bar-right {
    width: 760px !important;
  }
  .avatar-col {
    margin-right: 10px;
  }
  .button {
    background: ${theme.background.primary};
    font-weight: 600 !important;
    border-radius: 25px;
    color: ${theme.text.primary} !important;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      color: ${lighten(1, theme.text.primary)} !important;
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      ) !important;
      border-color: ${theme.button.primaryGradientStart} !important;
    }
    &:hover {
      outline: none !important;
      box-shadow: none !important;
      text-decoration: none !important;
      color: ${lighten(1, theme.text.primary)} !important;
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      ) !important;
      border-color: ${theme.button.primaryGradientStart} !important;
    }
    &.button-link {
      text-decoration: none;
      color: ${theme.text.primary} !important;
      &:focus {
        text-decoration: none !important;
        color: ${theme.button.primaryGradientStart} !important;
        background: none !important;
        border-color: transparent !important;
      }
      &:hover {
        text-decoration: none !important;
        color: ${theme.button.primaryGradientStart} !important;
        background: none !important;
        border-color: transparent !important;
      }
    }
  }
  .notification-icon {
    width: 20px;
    height: auto;
  }

  .login-active {
    font-weight: 600 !important;
    border-radius: 25px;
    color: ${lighten(1, theme.text.primary)} !important;
    background: linear-gradient(
      270deg,
      ${theme.button.primaryGradientStart} 0%,
      ${theme.button.primaryGradientStop} 122.67%
    ) !important;
    text-decoration: none !important;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      color: ${lighten(1, theme.text.primary)} !important;
      background: linear-gradient(
        270deg,
        ${theme.button.primaryGradientStart} 0%,
        ${theme.button.primaryGradientStop} 122.67%
      ) !important;
      border-color: ${theme.button.primaryGradientStart} !important;
    }
  }
`;

export default DesktopNavbar;
