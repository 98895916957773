import React from "react";
import { css } from "@emotion/react";
import { Trans } from "react-i18next";
import { rgba } from "polished";
import motion from "utils/polyFilledMotion";
import Congrats from "assets/images/refer-friend/rewards_graphic.png";
import EntryFee from "components/common/EntryFee";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { hideRefferalRedeenSuccessPopUp } from "redux/reducers/layoutReducer";
import { CommonModal } from "components/feature-specific/Modals/CommonModal";

const buttonFill = (theme: ITheme) => css`
  background: ${theme.button.primaryGradientStart};
  color: ${theme.foreground.primary};
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 2px 10px 10px 0 ${rgba(theme.button.primaryGradientStart, 0.25)};
  width: 60%;
  &:focus {
    outline: none;
  }
`;

const baseCss = (theme: ITheme) => css`
  position: relative;
  .points {
    color: ${theme.button.primaryGradientStart};
  }
  .reward-img {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: -55%;
  }
`;

const ReferralCodeRedeemSuccess = () => {
//   const theme = useTheme();
  const dispatch = useAppDispatch();
//   const [amount, setAmount] = useState("");
  const { referralReddemSuccess } = useAppSelector(state => state.layout);

 /*  useEffect(() => {
    if (referralReddemSuccess?.amount) {
      setAmount(referralReddemSuccess?.amount);
    } 
  }, [referralReddemSuccess]); */

  return (
    <CommonModal hideCrossIcon show={!!referralReddemSuccess?.amount}>
      <div css={baseCss}>
        <img className="reward-img" src={Congrats} alt="" />
        <div className="d-flex flex-column justify-content-center align-items-center p-2">
          {/* <div className="align-self-end">
            <Close onClick={onContinue} cursor="pointer" width={15} fill={theme.text.secondary} />
          </div> */}
          <h5 className="mt-4">
            <Trans i18nKey="congrats-you-go">
              <b>Congratulations!</b>
            </Trans>
          </h5>
          <h5>
            <Trans i18nKey="you-got-a-rewar">
              <b>You have received a reward</b>
            </Trans>
          </h5>
          <div className="d-flex flex-column justify-content-center ms-auto me-auto w-100">
            <span className="align-self-center mt-4 mb-4">
              <span className="points">
                <EntryFee payMethod="ticket" entry_fee={referralReddemSuccess?.amount} />
              </span>
            </span>
            <div className="mt-2 align-self-center w-100 text-center">
              <motion.button
                onClick={() => {
                  dispatch(hideRefferalRedeenSuccessPopUp());
                }}
                className="button-style"
                whileTap={{ scale: 0.9 }}
                css={buttonFill}
              >
                <Trans i18nKey="continue">Continue</Trans>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default ReferralCodeRedeemSuccess;
