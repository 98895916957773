import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CommonModal } from "../../Modals/CommonModal";
import { Watch } from "react-loader-spinner"; // Optional loading indicator

interface GoamaNequiPaymentModalProps {
  show: boolean;
  onHide: () => void;
  tournament: ITournament;
  onPayment: (params: any) => Promise<void>;
  onSuccess: () => void;
  loading: boolean;
}

const GoamaNequiPaymentModal: React.FC<GoamaNequiPaymentModalProps> = ({
  show,
  onHide,
  tournament,
  onPayment,
  onSuccess,
  loading,
}) => {
  const { t } = useTranslation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (!mobileNumber) {
      setError(t("Please enter a valid mobile number"));
      return;
    }

    setError("");

    const params = {
      tournament,
      mobileNumber, // Pass mobile number only for "NEQUI"
    };

    console.log("Mobile number in handleSubmit:", mobileNumber); // Log mobileNumber for debugging

    try {
      await onPayment(params); // Pass mobileNumber to onPayment
      onSuccess(); // Trigger success callback after payment
    } catch (err) {
      setError(t("Payment failed. Please try again."));
    }
  };

  return (
    <CommonModal
      show={show}
      onHide={onHide}
      // header={t("Payment Confirmation")}
      footer={
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleSubmit} disabled={loading || !mobileNumber}>
            {loading ? t("Processing...") : t("Submit")}
          </Button>
        </div>
      }
    >
      <div className="payment-modal-body">
        <h5>{t("Enter your mobile number to proceed with the payment")}</h5>
        <input
          type="tel"
          className="form-control mt-3"
          placeholder={t("Mobile Number")}
          value={mobileNumber}
          onChange={e => {
            setMobileNumber(e.target.value);
            console.log("Mobile number:", e.target.value); // Check if mobile number updates correctly
          }}
        />
        {error && <div className="text-danger mt-2">{error}</div>}
        {loading && (
          <div className="d-flex justify-content-center mt-4">
            <Watch height="50" width="50" color="#007bff" ariaLabel="loading-indicator" />
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default GoamaNequiPaymentModal;
