/**
 * * IMPORTANT
 * z-index order must be maintained
 * ticket animation objects are 2 steps above than modal
 * modal is 999998
 * modal backdrop is 999997 (one step below modal)
 * last updated: Ashik
 */
import React, { useCallback, useEffect, useState } from "react";
import { css, useTheme } from "@emotion/react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/Store";
// import tournamentsAPI from "actions/api/tournaments";
import { MotionButton } from "components/common/Buttons/MotionButton";
import classNames from "classnames";

import { Trans, useTranslation } from "react-i18next";

import Coin from "assets/images/Coin.png";
import Star from "assets/images/daily-login/star2.png";
import ImgCurrent from "assets/images/daily-login/Current-Day.png";
import ImgCurrentRed from "assets/clients/gcash/daily-login/Current-Day.png";
import ImgCurrentBlue from "assets/clients/alipayplus/daily-login/Current-Day.png";
// import ImgTickets from "assets/images/daily-login/Tickets.png";
import ImgTicket from "assets/images/G-Ticket.png";

import ImgCheckmark from "assets/images/daily-login/Checkmark.png";
import ImgNextDay from "assets/images/daily-login/Next-Day.png";
import ImgNextDayRed from "assets/clients/gcash/daily-login/Next-Day.png";
import ImgDailyLoginGo3 from "assets/images/daily-login/daily_badge_go3.png";
import ImgNextDayBlue from "assets/clients/alipayplus/daily-login/Next-Day.png";
import ImgDone from "assets/images/daily-login/Past-Day.png";
import ImgDoneRed from "assets/clients/gcash/daily-login/Past-Day.png";

import { fetchFoodpandaCoinInfo } from "redux/reducers/sdkReducer";
import { fetchTokenBalance } from "redux/reducers/tokenReducer";
import { fetchStatusPoints } from "redux/reducers/statusPointReducer";
import history from "HistoryWrapper";
// import wheelApi from "actions/api/wheelApi";
import { APP_SLUGS } from "constants/constants";
import { fetchPartnerBalance } from "redux/reducers/partnerInfoBalanceReducer";
import api from "actions/api";
import { isGo3 } from "utils/applicationSlug";
const zIndexModal = 999998;
const zIndexAnimationObject = zIndexModal + 2;

// Returns a Promise that resolves after "ms" Milliseconds
// const timer = (ms: any) => new Promise(res => setTimeout(res, ms));

function randomInteger(min: number, max: number) {
  return (Math.floor(Math.random() * max) + min) * -1;
}

const DailyLoginModal = ({
  onHide = () => {},
  onShow = () => {},
  setLoadingStatus = () => {},
}: {
  onShow?: Function;
  onHide?: Function;
  setLoadingStatus?: Function;
}) => {
  const { isDesktop } = useAppSelector(state => state.layout);
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [startAnim, setStartAnim] = useState(false);
  const [campaignData, setCampaignData] = useState<any>(null);
  const [config, setConfig] = useState<any>(null);
  const [hasClaimed, setClaimed] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(false);
  const { application, country, user, entryLogged } = useAppSelector((state: any) => state.common);
  const { data } = useAppSelector((state: any) => state.partnerBalance);
  const isRed = application?.slug === APP_SLUGS.GCASH;

  const isBlue =
    application?.slug === APP_SLUGS.GCASH_ALIPLUS ||
    application?.slug === APP_SLUGS.DANA_ALIPLUS ||
    application?.slug === APP_SLUGS.TNG_ALIPLUS;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { useSuspense: false });

  const hideModal = () => {
    setShow(false);
    onHide();
  };

  useEffect(() => {
    const { location } = history;
    if (location.pathname === "/" || location.pathname === "/tournament") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, []);

  useEffect(() => {
    if (show) {
      onShow();
      document.body.classList.add("daily-login-reward_modal--backdrop");
    } else {
      document.body.classList.add("daily-login-reward_modal--backdrop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const startLoading = useCallback(() => {
    // setLoading(true);
    setLoadingStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopLoading = useCallback(() => {
    // setLoading(false);
    setLoadingStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (application && country && user && entryLogged && isHomePage) {
      startLoading();
      api
        .get(`tournaments/campaigns/daily-return/${application?.slug}/${country}/`)
        .then((res: any) => {
          const result = res;
          const { data } = result;
          if (data?.conf) {
            setConfig(data?.conf);
          }
          if (data?.program && data?.program?.length > 0) {
            const temp = data?.program?.sort((a: any, b: any) => {
              return a.serial - b.serial;
            });
            result.data.program = temp;
            const currentDay = data?.program?.find((item: any) => item.activity === "current");
            if (!currentDay) {
              result.data.program[0].activity = "current";
            }
            setCampaignData(result.data);
            setShow(true);
          } else {
            setShow(false);
          }
        })
        .catch(() => {
          setShow(false);
        })
        .finally(() => stopLoading());
    }
  }, [application, country, user, entryLogged, startLoading, stopLoading, isHomePage]);

  // const getRewardTypeText = (rewardType: string, rewardAmount: number) => {
  //   if (!rewardType) return "";
  //   let rewardTypeText = rewardType[0].toUpperCase() + rewardType.slice(1);
  //   if (rewardAmount > 1) {
  //     rewardTypeText = rewardTypeText + "s";
  //   }
  //   return rewardTypeText;
  // };

  const getRewardTypeText = (rewardType: string, rewardAmount: number) => {
    if (!rewardType) return "";
    let rewardTypeText = rewardType[0].toUpperCase() + rewardType.slice(1);
    if (rewardAmount > 1) {
      rewardTypeText = rewardTypeText + "s";
    }

    return rewardTypeText;
  };

  const getRewardAnimImg = () => {
    const current = campaignData?.program?.find((item: any) => item.activity === "current");
    if (current && current.reward_type === "ticket") {
      if (application?.applicationsetting?.user_journey_image) {
        // return application?.applicationsetting?.user_journey_image;
        return application?.applicationsetting?.user_journey_image;
      } else {
        return ImgTicket;
      }
    } else if (current && current.reward_type === "token") {
      return application?.payment_channel?.custom_currency_icon || Coin;
    } else if (current && current.reward_type === "partner_point") {
      if (application?.applicationsetting?.enable_partner_balance) {
        return data?.custom_currency_icon;
      }
    }
    return "";
  };

  /* const getRewardAnimElem = () => {
    const current = campaignData?.program?.find(
      (item: any) => item.activity === "current"
    );
    if (current && current.reward_type === "ticket") {
      return ticketEl;
    } else if (current && current.reward_type === "token") {
      return tokenEl;
    }
    return null;
  }; */

  const getRewardAnimObject = () => {
    const current = campaignData?.program?.find((item: any) => item.activity === "current");
    if (current && current.reward_type === "ticket") {
      return (document.getElementsByClassName("ticket-status")[0] as any) || null;
    } else if (current && current.reward_type === "token") {
      return (document.getElementsByClassName("token-status")[0] as any) || null;
    } else if (current && current.reward_type === "partner_point") {
      return (document.getElementsByClassName("partner-status")[0] as any) || null;
    }
    return null;
  };

  const getRewardImg = (rewardType: string) => {
    if (rewardType === "ticket") {
      if (application?.applicationsetting?.user_journey_image) {
        return application?.applicationsetting?.user_journey_image;
      } else {
        return ImgTicket;
      }
    } else if (rewardType === "token") {
      return application?.payment_channel?.custom_currency_icon || Coin;
    } else if (rewardType === "partner_point") {
      if (application?.applicationsetting?.enable_partner_balance) {
        return data?.custom_currency_icon;
      }
    }
    return "";
  };

  const animationDuration = 0.8;
  const animationDelayRatio = 0.09;
  const totalAnimatedElements = 20;

  const getImageCurrentDay = () => {
    if (isRed) {
      return ImgCurrentRed;
    } else if (isBlue) {
      return ImgCurrentBlue;
    }
    if (isGo3) {
      return ImgDailyLoginGo3;
    }
    return ImgCurrent;
  };

  const getImageNextDay = () => {
    if (isRed) {
      return ImgNextDayRed;
    } else if (isBlue) {
      return ImgNextDayBlue;
    }
    if (isGo3) {
      return ImgDailyLoginGo3;
    }
    return ImgNextDay;
  };

  const getImagePastDay = () => {
    if (isRed) {
      return ImgDoneRed;
    } else if (isBlue) {
      return ImgDoneRed;
    }
    if (isGo3) {
      return ImgDailyLoginGo3;
    }
    return ImgDone;
  };

  // const getAnimImg = () => {
  //   const endAnimationObject = getRewardAnimObject();
  //   const startAnimationObject = document?.querySelector(".current-img");
  //   const endPositionLeft = endAnimationObject?.offsetLeft;
  //   const endPositionTop = endAnimationObject?.offsetTop;
  //   const startPositionLeft = startAnimationObject?.getBoundingClientRect().left || 0;
  //   const startPositionTop = startAnimationObject?.getBoundingClientRect().top || 0;
  //   const list = [];
  //   for (let i = 0; i <= totalAnimatedElements; i += 1) {
  //     list.push(
  //       <div
  //         key={`animImg${i}`}
  //         className={`${startAnim && "move"}`}
  //         css={css`
  //           position: absolute;
  //           z-index: ${zIndexAnimationObject};
  //           visibility: hidden;
  //           &.move {
  //             visibility: hidden;
  //             animation: straightLine ${animationDuration}s linear;
  //             animation-delay: ${animationDelayRatio * i}s;
  //             @keyframes straightLine {
  //               0% {
  //                 left: ${startPositionLeft + 25}px;
  //                 top: ${startPositionTop}px;
  //                 visibility: visible;
  //                 transform: translateX(50%);
  //                 opacity: 1;
  //               }
  //               5% {
  //                 transform: translateX(100%);
  //               }
  //               10% {
  //                 transform: translateX(200%);
  //               }
  //               20% {
  //                 transform: translateX(250%);
  //               }
  //               30% {
  //                 transform: translateX(300%);
  //               }
  //               40% {
  //                 transform: translateX(350%);
  //               }
  //               50% {
  //                 transform: translateX(350%);
  //               }
  //               60% {
  //                 transform: translateX(350%);
  //               }
  //               70% {
  //                 transform: translateX(300%);
  //               }
  //               80% {
  //                 transform: translateX(250%);
  //               }
  //               85% {
  //                 transform: translateX(200%);
  //               }
  //               90% {
  //                 transform: translateX(150%);
  //                 opacity: 1;
  //               }
  //               100% {
  //                 transform: translateX(100%);
  //                 opacity: 1;
  //                 visibility: hidden;
  //                 top: ${endPositionTop}px;
  //                 left: ${endPositionLeft}px;
  //               }
  //             }
  //             /* visibility: visible; */
  //             /* top: 19px; */
  //           }
  //         `}
  //       >
  //         <img
  //           className={`${startAnim && "move-ticket"}`}
  //           css={css`
  //             &.move-ticket {
  //               animation: startTicketAnim ${animationDuration}s linear;
  //               animation-delay: ${animationDelayRatio * i}s;
  //               @keyframes startTicketAnim {
  //                 0% {
  //                   transform: rotate(-30deg) scale(0.9);
  //                 }
  //                 5% {
  //                   /* transform: translateX(100%); */
  //                 }
  //                 10% {
  //                 }
  //                 20% {
  //                   transform: scale(0.94);
  //                 }
  //                 30% {
  //                   transform: rotate(30deg) scale(0.96);
  //                 }
  //                 40% {
  //                   transform: scale(0.98);
  //                 }
  //                 50% {
  //                   transform: scale(1) rotate(-30deg);
  //                 }
  //                 60% {
  //                   /* transform: translateX(350%); */
  //                 }
  //                 70% {
  //                   transform: rotate(30deg) scale(0.98);
  //                 }
  //                 80% {
  //                   /* transform: translateX(250%); */
  //                 }
  //                 85% {
  //                   /* transform: rotate(-15deg); */
  //                   /* transform: translateX(200%); */
  //                   transform: scale(0.94);
  //                 }
  //                 95% {
  //                   /* transform: translateX(150%); */
  //                   opacity: 0.7;
  //                   transform: scale(0.9);
  //                 }
  //                 100% {
  //                   opacity: 0;
  //                   transform: scale(0.8);
  //                   /* transform: rotate(15deg); */
  //                 }
  //               }
  //             }
  //           `}
  //           width="20"
  //           src={getRewardAnimImg()}
  //           alt=""
  //         />
  //         <div className="position-relative">
  //           <div>
  //             <img
  //               className={`position-absolute ${startAnim && "move-star"}`}
  //               css={css`
  //                 top: ${randomInteger(10, 50)}px;
  //                 left: ${randomInteger(10, 10)}px;
  //                 &.move-star {
  //                   animation: startStartAnim 0.6s linear infinite;
  //                   animation-delay: ${0.07 * i}s;
  //                   @keyframes startStartAnim {
  //                     0% {
  //                       opacity: 1;
  //                       transform: scale(2);
  //                     }
  //                     10% {
  //                       opacity: 0;
  //                       transform: scale(1.2);
  //                     }
  //                     30% {
  //                       opacity: 1;
  //                       transform: scale(1);
  //                     }
  //                     40% {
  //                       opacity: 0.1;
  //                     }
  //                     60% {
  //                       opacity: 1;
  //                     }
  //                     90% {
  //                       opacity: 0.5;
  //                     }
  //                     100% {
  //                       opacity: 0.5;
  //                       transform: scale(1.2);
  //                     }
  //                   }
  //                 }
  //               `}
  //               width="50"
  //               src={Star}
  //               alt=""
  //             />
  //           </div>
  //         </div>
  //       </div>,
  //     );
  //   }
  //   return list;
  // };

  const getAnimImg = () => {
    const endAnimationObject = getRewardAnimObject();
    const startAnimationObject = document?.querySelector(".current-img");
    const endPositionLeft = endAnimationObject?.offsetLeft;
    const endPositionTop = endAnimationObject?.offsetTop;
    const startPositionLeft = startAnimationObject?.getBoundingClientRect().left || 0;
    const startPositionTop = startAnimationObject?.getBoundingClientRect().top || 0;
    const list: JSX.Element[] = [];

    for (let i = 0; i <= totalAnimatedElements; i += 1) {
      list.push(
        <div
          key={`animImg${i}`}
          className={`${startAnim && "move"}`}
          css={css`
            position: absolute;
            z-index: ${zIndexAnimationObject};
            visibility: hidden;
            &.move {
              visibility: hidden;
              animation: straightLine ${animationDuration}s ease-in-out;
              animation-delay: ${animationDelayRatio * i}s;
              @keyframes straightLine {
                0% {
                  left: ${startPositionLeft + 25}px;
                  top: ${startPositionTop}px;
                  visibility: visible;
                  transform: translateX(50%) scale(0.8) rotate(0deg);
                  opacity: 1;
                }
                25% {
                  transform: translateX(150%) scale(1.2) rotate(10deg);
                }
                50% {
                  transform: translateX(300%) scale(1) rotate(-10deg);
                }
                75% {
                  transform: translateX(450%) scale(1.1) rotate(10deg);
                  opacity: 1;
                }
                95% {
                  left: ${endPositionLeft}px;
                  top: ${endPositionTop}px;
                  visibility: visible;
                  transform: translateX(500%) scale(0.8) rotate(0deg);
                  opacity: 1;
                }
                100% {
                  opacity: 0;
                  visibility: hidden;
                }
              }
            }
          `}
        >
          <img
            className={`${startAnim && "move-ticket"}`}
            css={css`
              &.move-ticket {
                animation: startTicketAnim ${animationDuration}s ease-in-out;
                animation-delay: ${animationDelayRatio * i}s;
                @keyframes startTicketAnim {
                  0% {
                    transform: rotate(-30deg) scale(0.9);
                  }
                  25% {
                    transform: rotate(30deg) scale(1.2);
                  }
                  50% {
                    transform: rotate(-30deg) scale(1);
                  }
                  75% {
                    transform: rotate(30deg) scale(1.1);
                  }
                  95% {
                    transform: rotate(0deg) scale(0.8);
                    opacity: 1;
                  }
                  100% {
                    opacity: 0;
                  }
                }
              }
            `}
            width="20"
            src={getRewardAnimImg()}
            alt=""
          />
          <div className="position-relative">
            <div>
              <img
                className={`position-absolute ${startAnim && "move-star"}`}
                css={css`
                  top: ${randomInteger(10, 50)}px;
                  left: ${randomInteger(10, 10)}px;
                  &.move-star {
                    animation: startStarAnim 0.6s ease-in-out infinite;
                    animation-delay: ${0.07 * i}s;
                    @keyframes startStarAnim {
                      0% {
                        opacity: 1;
                        transform: scale(2);
                      }
                      10% {
                        opacity: 0;
                        transform: scale(1.2);
                      }
                      30% {
                        opacity: 1;
                        transform: scale(1);
                      }
                      40% {
                        opacity: 0.1;
                      }
                      60% {
                        opacity: 1;
                      }
                      90% {
                        opacity: 0.5;
                      }
                      100% {
                        opacity: 0.5;
                        transform: scale(1.2);
                      }
                    }
                  }
                `}
                width="50"
                src={Star}
                alt=""
              />
            </div>
          </div>
        </div>,
      );
    }

    return list;
  };

  // if (!loaded) return null;
  if (!show) return null;
  return (
    <>
      <Modal
        backdropClassName="daily-login-reward_modal--backdrop"
        onHide={() => {}}
        show={show}
        centered
        css={baseCss}
        style={loaded ? {} : { visibility: "hidden" }}
      >
        <div className="text-center pt-3 pb-2">
          <span
            css={css`
              color: ${config?.banner_text_color || theme.button.primaryGradientStart} !important;
            `}
            className="text-header"
          >
            {config?.banner_title || <Trans i18nKey="daily-login">Daily Login Rewards!</Trans>}
          </span>
        </div>
        <div className="pb-3 rewards-container px-2">
          <div className="">
            <div className="text-center mb-2 mt-2 d-flex flex-row flex-wrap align-items-end justify-content-center">
              {campaignData?.program?.map((item: any, index: number) => {
                if (item.activity === "current") {
                  return (
                    <div key={item?.serial} className="day-container position-relative">
                      <img
                        // ref={getRewardAnimElem()}
                        className="img-ticket current-img"
                        src={getImageCurrentDay()}
                        alt=""
                        onLoad={() => setLoaded(true)}
                      />
                      <br />
                      <span className="text-reward">
                        <b>
                          {item.reward}{" "}
                          <span>
                            {/* {getRewardTypeText(item.reward_type, item.reward)} */}
                            {t(getRewardTypeText(item.reward_type, item.reward).toLowerCase(), {
                              defaultValue: getRewardTypeText(item.reward_type, item.reward),
                            })}
                          </span>
                        </b>
                      </span>
                      <div
                        className={classNames({
                          "position-absolute img-inside-container current": true,
                          "second-line": index > 3,
                        })}
                      >
                        <div>
                          <img className="img" src={getRewardImg(item.reward_type)} alt="" />
                          <br />
                          <span className="text-day-number">
                            <small>
                              <b>{item.serial_title}</b>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                } else if (item.activity === "upcoming") {
                  return (
                    <div key={item?.serial} className="day-container position-relative">
                      <img className="img-ticket" src={getImageNextDay()} alt="" />
                      <br />
                      <span className="text-reward">
                        <b>
                          {item.reward}{" "}
                          <span>
                            {/* {getRewardTypeText(item.reward_type, item.reward)} */}

                            {t(getRewardTypeText(item.reward_type, item.reward).toLowerCase(), {
                              defaultValue: getRewardTypeText(item.reward_type, item.reward),
                            })}
                          </span>
                        </b>
                      </span>
                      <div
                        className={classNames({
                          "position-absolute img-inside-container": true,
                          "second-line": index > 3,
                        })}
                      >
                        <div>
                          <img className="img upcoming" src={getRewardImg(item.reward_type)} alt="" />
                          <br />
                          <span className="text-day-number">
                            <small>
                              {/* <b>Day {index + 1}</b> */}
                              <b>{item.serial_title}</b>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                } else if (item.activity === "done") {
                  return (
                    <div key={item?.serial} className="day-container position-relative">
                      <img className="img-ticket past-day-img" src={getImagePastDay()} alt="" />
                      <br />
                      <span className="text-reward">
                        <b>
                          {item.reward}{" "}
                          <span>
                            {/* {getRewardTypeText(item.reward_type, item.reward)} */}
                            {t(getRewardTypeText(item.reward_type, item.reward).toLowerCase(), {
                              defaultValue: getRewardTypeText(item.reward_type, item.reward),
                            })}
                          </span>
                        </b>
                      </span>
                      <div
                        className={classNames({
                          "position-absolute img-inside-container": true,
                          "second-line": index > 3,
                        })}
                      >
                        <div>
                          <img className="img checkmark" src={ImgCheckmark} alt="" />
                          <br />
                          <span className="text-day-number">
                            <small>
                              <b>{item.serial_title}</b>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
        <div className="text-center mb-3 btn-footer">
          <MotionButton
            bgColor={config?.cta_button_color}
            textColor={config?.cta_text_color}
            disabled={startAnim}
            customContainerClassNames="ms-auto me-auto text-center"
            onClick={() => {
              if (hasClaimed) {
                hideModal();
              }
              let headElem = document.getElementsByClassName("navbar-home")[0] as any;
              const insideHeadElem = headElem?.getElementsByClassName("header-style")[0] || null;
              const logosInsideHeadElem = headElem?.getElementsByClassName("navbar-logos")[0] || null;
              if (!startAnim && !hasClaimed) {
                setStartAnim(true);
                // mobile
                if (!isDesktop) {
                  try {
                    if (logosInsideHeadElem) {
                      logosInsideHeadElem.style.visibility = "hidden";
                    }
                  } catch (e) {
                    //
                  }
                  try {
                    if (insideHeadElem) {
                      insideHeadElem.classList.add("bg-transparent");
                    }
                  } catch (e) {
                    //
                  }
                } else {
                  headElem = document.getElementsByClassName("top-bar-container")[0] as any;
                }

                if (headElem) {
                  headElem.style.zIndex = zIndexModal + 1;
                  headElem.classList.add("bg-transparent");
                }
                setTimeout(() => {
                  const campaigns = campaignData;
                  const currentDayIndex = campaigns?.program?.findIndex((item: any) => item.activity === "current");
                  if (currentDayIndex >= 0) {
                    campaigns.program[currentDayIndex].activity = "done";
                  }
                  // campaigns.program = [...campaigns.program];

                  api
                    .get(`tournaments/campaigns/daily-return/claim/${campaigns.program[currentDayIndex].id}/`)
                    .then(async () => {
                      if (application?.slug === "foodpandaapp") {
                        await dispatch(fetchFoodpandaCoinInfo());
                      } else {
                        if (application?.applicationsetting?.enable_coin) await dispatch(fetchTokenBalance(country));
                      }
                      await dispatch(fetchStatusPoints());

                      if (application?.applicationsetting?.enable_partner_balance) {
                        dispatch(fetchPartnerBalance(application?.slug));
                      }

                      setCampaignData({ ...campaigns });
                      setStartAnim(false);
                      setClaimed(true);
                      setTimeout(() => {
                        if (headElem && headElem.style?.zIndex) {
                          headElem.style.zIndex = 33;
                          headElem.classList.remove("bg-transparent");
                          try {
                            if (insideHeadElem) {
                              insideHeadElem.classList.remove("bg-transparent");
                            }
                          } catch (e) {
                            //
                          }
                          try {
                            if (logosInsideHeadElem) {
                              logosInsideHeadElem.style.visibility = "visible";
                            }
                          } catch (e) {
                            //
                          }
                        }
                        setTimeout(() => {
                          hideModal();
                        }, 1000);
                      }, 100);
                    })
                    .catch(e => {
                      console.error(e);
                      hideModal();
                    })
                    .finally(() => {});
                }, 2500);
              } else setStartAnim(false);
            }}
            title={
              <b>
                {!hasClaimed ? (
                  config?.cta_text || <Trans i18nKey="claim-now">Claim Ndsdow</Trans>
                ) : (
                  <Trans i18nKey="close">Claimed</Trans>
                )}
              </b>
            }
            width="40%"
          />
        </div>
      </Modal>
      {getAnimImg()}
      {/* {getAnimStarImg()} */}
    </>
  );
};

export default DailyLoginModal;

const baseCss = (theme: ITheme) => css`
  z-index: ${zIndexModal} !important;
  .modal-content {
    margin: 0.4rem;
    border-radius: 1.3rem;
  }
  .text-reward {
    span {
      font-size: 0.6em;
    }
  }
  .rewards-container {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
  .btn-footer {
    position: absolute;
    width: 100%;
    bottom: -2.4rem;
  }
  .text-header {
    color: ${theme.button.primaryGradientStart};
    font-size: 1.6em;
    font-weight: bold;
  }
  .day-container {
    /* flex: 1 0 21%; explanation below */
    flex: 0.2 0 20%;
    margin-bottom: 0.8rem;
    /* margin: 5px; */
  }
  .img-ticket {
    width: 79px;
    height: 100%;
    &.current-img {
      width: 90px;
    }
  }
  .img-inside-container {
    /* left: 20%;
    bottom: 40%; */
    bottom: 40%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    &.second-line {
      /* left: 27%; */
    }
    &.current {
      /* left: 12%; */
      bottom: 36%;
      .checkmark {
        width: 80px;
      }
      &.second-line {
        /* left: 22%; */
      }
    }
    .text-day-number {
      /* padding-right: 12px; */
      padding-right: 0;
      color: #fff;
    }
  }
  .img {
    width: 40px;
    height: 100%;
    &.checkmark {
      width: 38px;
    }
    &.upcoming {
      width: 30px;
    }
  }
`;
